@import '../../../styles/variables.scss';

.filters {
  @include transition;
  left: 5%;
  position: absolute;
  background-color: $dark;
  top: -20px;
  border-radius: $radius;
  border: 1px solid $white;
  width: 70px;
  z-index: 300;
  display: flex;
  color: $white;
  font-size: 14px;
  &.disabled {
    background-color: rgb(189, 189, 189);
    opacity: 0;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.isOpen {
    width: calc((201px * 4) + 43px);
    &.onlySearch {
      width: calc(201px + 43px);
    }
  }
  &.onlySearch {
    width: 50px;
  }
  &.mutations {
    width: calc((201px * 5) + 43px);
  }
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0Px;
    }
  }
  .btn-filters {
    width: 45px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter {
    @include transition;
    width: 0px;
    opacity: 0;
    &.isOpen {
      width: 200px;
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border {
      border-right: 1px solid $white;
    }
    button {
      border-radius: 30px;
      margin-right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($white, 0.2)
      }
    }
    p {
      padding-left: 10px;
      color: $white;
      margin: 0px;
    }
    input {
      font-family: "Ubuntu-Regular";
      background-color: transparent;
      outline: none;
      border: 0px;
      width: 100%;
      color: #FFFFFF;
      font-size: 14px;
      &::placeholder {
        color: #FFFFFF;

      }
    }
    .list {
      position: absolute;
      top: 40px;
      width: 200px;
      padding: 0px 10px;
      max-height: 50vh;
      overflow-y: auto;
      background-color: $dark;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      border-right: 1px solid $white;
      p {
        color: #5F657C;
      }
      .item {
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        align-items: center;
        margin: 15px 0px;
        .pathology {
          width: 100%;
          display: flex;
          align-items: center;
        }
        .color-circle {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          margin-left: 10px;
          opacity: 0;
        }
        &.mutations {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          .mutation-item {
            display: flex;
            align-items: center;
            margin: 10px;
            p {
              color: #5F657C;
            }
            &.isActive {
              p {
                color: $white;
              }
            }
          }
          .color-circle {
            opacity: 1;
          }
          p {
            color: $white;
          }
        }
        &.isActive {
          p {
            color: $white;
          }
          .color-circle {
            opacity: 1;
          }
        }
      }
    }
  }
}