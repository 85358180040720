@import '../../../styles/variables.scss';

.date-picker {
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    font-family: "Ubuntu-Regular";
    font-size: 21px;
  }
  .MuiPickersDay-daySelected {
    color: #fff;
    font-weight: 500;
    background-color: $dark;
  }.MuiIconButton-root {
    padding: 0px;
  } 
  svg {
    fill: rgba($white , 0.5 );
    &:hover {
      fill: $white;
    }
  }

}