@import './variables.scss';

.grid-container {
  @include transition;
  width: 100%;
  justify-content: center;
  overflow: auto;
  display: flex;
  padding: 60px 0px;
  position: relative;
  &.panel-open {
    justify-content: flex-start;
    @media only screen and (min-width: $md-screen) {
      padding: 20px 2%;
      overflow-x: visible;
    }
    @media only screen and (min-width: $lg-screen) {
      padding: 20px 5%;
    }
  }
  .cards-grid {
    @include transition;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 200px;
    grid-gap: 20px;
    justify-content: flex-start;
    margin-bottom: 10px;
    @media only screen and (min-width: $sm-screen) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fill, 200px);
    }
    @media only screen and (min-width: $md-screen) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (min-width: $lg-screen) {
      grid-template-columns: repeat(4, 1fr);
    }
    &.panel-open {
      @media only screen and (min-width: $md-screen) {
        width: 50%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(auto-fill, 180px);
      }
      @media only screen and (min-width: $lg-screen) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .add-card {
      @include transition;
      outline: 2px dashed $dark;
      outline-offset: -2px;
      border-radius: $radius;
      min-height: 180px;
      width: 100%;
      padding: 0px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $white;
        outline: 2px solid $dark;
      }
      h3 {
        text-align: center;
        text-transform: uppercase;
        font-family: "AnyBody-SemiBold";
        font-size: 21px;
      }
    }
  }
}

.panel {
  position: fixed;
  right: 0;
  overflow: auto;
  top: 60px;
  width: 100%;
  height: 100vh;
  background-color: $white;
  padding: 0px 2%;
  transform: translateX(300%);
  @include transition;
  @media only screen and (min-width: $md-screen) {
    width: calc(50% - 10px);
    padding: 0px;
    top: 20px;
    bottom: 20px;
    height: calc(100vh - 40px);
    box-shadow: $panel-shadow;
  }

  .btn-close {
    right: 0px;
    position: absolute;
    margin: 2%;
  }
  .btn-edit {
    font-family: "AnyBody-SemiBold";
    text-transform: uppercase;
    background-color: $dark;
    padding: 10px 20px;
    border-radius: $radius;
    color: $white;
    &.delete {
      &:hover {
        background-color: $error;
      }
    }
  }
  .header {
    margin: $margin-md 2%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgba($dark , 0.25 );
    padding-bottom: $margin-md;
    .color-label {
      width: 15px ;
      height: 15px;
      transform: translate(-24px , -27px )
    }
    h2 {
      font-family: "AnyBody-SemiBold";
      font-size: 36px;
      word-break: break-all;
    }
    a {
      @include transition;
      display: inline-block;
      margin-top :10px;
      color: rgba($dark , 0.5 );
      text-decoration: none;
      svg {
        transform: translateY(4px);
        margin-right: 2px;
      }
      &:hover {
        color: $dark;
        text-decoration: underline;
      }
    }
    h3, h2 {
      margin: 0px;
    }
    @media only screen and (min-width: $sm-screen) {
      margin: 40px;
    }
    &.patient {
      @media only screen and (min-width: $sm-screen) {
        margin: 40px 40px 0px 40px;
      }
      .date {
        margin: 10px 0px 0px 0px;
      }
    }
  }
  .panel-content {
    margin: $margin-md 2%;
    @media only screen and (min-width: $sm-screen) {
      margin: 40px;
    }
    &.patient {
      @media only screen and (min-width: $sm-screen) {
        margin: 0px 40px 40px 40px;
      }
      .patient-infos {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba($dark , 0.25 );
        margin-bottom: 20px;
        .col-2 {
          width: calc(50% - 20px);
          padding-top: 20px;
          .form-content {
            max-width: 65%;
          }
        }
        .border {
          min-height: 100%;
          width: 2px;
          border-right: 1px solid rgba($dark , 0.25 );
        } 
      }
    }
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: $margin-md;
      @media only screen and (min-width: $sm-screen) {
        flex-direction: row;
        justify-content: space-between;
      }
      &.row {
        flex-direction: row;
        justify-content: flex-start;
      }
      &.col {
        @media only screen and (min-width: $sm-screen) {
          flex-direction: column;
          justify-content: space-between;
        }
        .form-content {
          @media only screen and (min-width: $sm-screen) {
            width: 100%;
          }
        }
      }
      &.legal {
        border-top: 1px solid rgba($dark , 0.25 );
        padding-top: 20px;
        a {
          color: rgba($dark , 0.50 );
        }
      }
      &.minirin {
        padding-bottom: 21px;
        border-bottom: 1px solid rgba($dark , 0.25 );
      }
      &.delete {
        display: flex;
        justify-content: flex-start;
        button {
          margin-right: 20px;
        }
      }
      &.score {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 21px;
        border-bottom: 1px solid rgba($dark , 0.25 );
        .score-list {
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          .btn-score {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 3px;
            min-width: 26px;
            height: 26px;
            border-radius: 3px;
          }
        }
      }
      &.inhibitors {
        display: flex;
        flex-direction: column;
        .title {
          padding-bottom: 20px;
        }
        .patient-infos {
          border-top: 1px solid rgba($dark , 0.25 );
          .form-group {
            width: 100%;
          }
        }
      }
      &.mutations {
        border-top: 1px solid rgba($dark , 0.25 );
        padding-top: $margin-md;
        .number {
          color: $dark;
        }
        @media only screen and (min-width: $sm-screen) {
          flex-direction: column;
        }
      }
      .form-label {
        width: 100%;
        @media only screen and (min-width: $sm-screen) {
          width: 20%;
        }
      }
      .form-content {
        width: 100%;
        &.legal {

        }
        @media only screen and (min-width: $sm-screen) {
          width: 76%;
        }
        .text-list {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 10px;
          .list-item {
            display: flex;
            align-items: center;
            background-color: #EBECED;
            color: $dark;
            border-radius: 3px;
            margin-bottom: 8px;
            p.text {
              font-size: 14px;
              margin: 2px 0px;
              background-color:  #EBECED;
            }
            button {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .MuiInputBase-input {
          margin: 0px;
        }
        .new-dosage-row {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          input {
            width: calc(33% - 10px); 
          } 
        }
       }
      label {
        color: rgba($dark , 0.5 );
      }
      .note {
        color: rgba($dark , 0.5 );
        margin-top: 20px;
        font-size: 12px;
      }
      p {
        margin: 5px 5px 5px 0px ;
      }
    }
  }

  &.isOpen {
    transform: translateX(0px);
    z-index: 30;
  }

  &.isEdit {
    background-color: $dark;
    .btn-edit {
      background-color: $white;
      color: $dark;
    }
    .header {
      border-bottom: 1px solid rgba($white , 0.25 );
      align-items: flex-start;
      h2 {
        color: $white
      }
      h3 {
        color: rgba($white , 0.50 );
      }
      label {
        color: rgba($white , 0.50 );
      }
      .code {
        margin-top: 5px;
        display: flex;
      }
    }
    .panel-content {
      padding-bottom: 40px;
      &.patient {
        .patient-infos {
          border-bottom: 1px solid rgba($white , 0.25 );
          .border {
            border-right: 1px solid rgba($white , 0.25 );
          }
          .form-content {
            margin-bottom: 0px
          } 
        }
        .form-content {
          margin-bottom: 20px;
          textarea {
            margin-bottom: 10px;
          }
          .text-list {
            margin-bottom: 0px;  
            .list-item {
              background-color: #5F657C;
              color: $white;
              p.text {
                font-size: 14px;
                margin: 2px 0px;
                background-color: #5F657C;
              }
            }
          }
        }
       
      }
      .patient-infos {
        border-bottom: 1px solid $dark-lighter;
        .border {
          border-right: 1px solid $dark-lighter;
        } 
      }
      .form-group {
        label {
          color: rgba($white , 0.50 );
        }
        .label {
          color: rgba($white , 0.50 );
        }
        &.legal {
          border-top: 1px solid rgba($white , 0.25 );
          padding-top: 20px;
          .note {
            color: rgba($white , 0.5 );
            margin-top: 20px;
            font-size: 12px;
          }
          label {
            margin-bottom: 20px;
          }
        }
        &.minirin {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 21px;
          border-bottom: 1px solid rgba($white , 0.25 );
          .select {
            width: 300px;
          }
        }
        &.score {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 21px;
          border-bottom: 1px solid rgba($white , 0.25 );
          .score-list {
            margin-left: 10px;
            .btn-score {
              margin-right: 3px;
              min-width: 26px;
              height: 26px;
              border-radius: 3px;
              background-color: rgba($white , 0.25 );
              &:hover {
                background-color: rgba($white , 0.50 );
              }
              &.isActive {
                background-color: $white;
              }
            }
          }
        }
        &.inhibitors {
          display: flex;
          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba($white , 0.25 );
          }
          .patient-infos {
            border-top: 1px solid rgba($white , 0.25 );
            .col-2 {
              padding-bottom: 20px;
            }
            .form-group {
              width: 100%;
              flex-direction: column;
              .form-label {
                width: 100%;
              }
              .form-content {
                width: 100%;
              }
            }
          }
        }
        .react-colorful {
          width: 100%;
        }
      }
    }
  }
}

.text-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    font-size: 12px;
    margin: 0px 10px 10px 0px;
    background-color: rgba($dark , 0.2);
    padding: 5px 10px;
    border-radius: 5px;
  }
}

form {
  .error {
    color: $error;
    margin: 0px;
    margin-top: 5px;
    font-size: 12px;
  }
  input , textarea , .MuiInputBase-input  {
    @include transition;
    width: 100%;
    max-width: 100%;
    font-family: "Ubuntu-Regular";
    padding: 5px 10px;
    font-size: 16px;
    height: auto;
    border-radius: 3px;
    outline: none;
    border: 1px solid $dark-lighter;
    color: $white;
    background-color: rgba($dark-lighter, 0.1);
    &.code {
      font-family: "AnyBody-SemiBold";
      font-size: 36px;
      margin: 0px;
      padding: 0px 10px;
    }
    &:focus {
      border: 1px solid $white;
    }
  }
  textarea {
    min-height: 120px;
    padding: 10px;
  }
  .btn-primary {
    font-family: "AnyBody-SemiBold";
    text-transform: uppercase;
    // border: 1px solid $dark-lighter;
    border-radius: 3px;
    padding: 6px 10px;
    margin: 10px 0px;
    font-size: 14px;
    background-color: $dark-lighter;
    color: $dark;
    &:hover {
      transform: scale(1.02);
    }
  }
  .edit-mutation-row {
    display: flex;
    align-items: center;
    input {
      margin: 0px 10px 10px 0px;
    }
  } 
}
