@import './variables.scss';
@import './form-pages.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
}

//MAIN-CONTAINERS

.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.page-container {
  top: 60px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  opacity: 1;
  // animation: fade 1s timing-function 2s 1 normal forwards;
  // @include animation-fade(fade);
  @media only screen and (min-width: $md-screen) {
    top: 80px;
    height: calc(100vh - 80px);
  }
}

.content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

//MAIN_CLASSES

.uppercase {
  text-transform: uppercase;
}

.semi-bold {
  font-family: "AnyBody-SemiBold";
}

.bold {
  font-family: "Anybody-Bold";
}

.regular {
  font-family: "Anybody-Regular";
}

label {
  font-family: "Ubuntu-Regular";
}

button {
  @include btn;
}

.m-md {
  margin: $margin-md
} 

.text-center {
  text-align: center;
}

.text-error {
  font-size: $small;
  margin: 6px;
  min-height: 16px;
  color: $error;
}

.grey-label {
  font-family: "AnyBody-SemiBold";
  text-transform: uppercase;
  color: rgba($dark , 0.25 );
}

h1 {
  text-transform: uppercase;
  font-size: 21px;
  font-family: "Anybody-Bold";
}

.btn {
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: $radius;
  font-size: 14px;
  text-transform: uppercase;
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $dark;
    &:hover {
      background-color: $dark;
      transform: scale(1.03)
    }
  }
}

input {
  font-family: "Ubuntu-Regular";
  &.primary {
    border: none ;
    height: 50px;
    border-radius: 3px;
    padding-left: $margin-md;
    color: $dark;
    background-color: $white;
    box-shadow: 0px 10px 28px 0px rgba(156,167,177,0.30);
    font-size: 16px;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
    &:focus {
      outline: none;
    }
  }
}

