@import '../../../../styles/variables.scss';

.genealogy {
  padding: 0px;
  .graph-container {
    height: 100%;
    width: 100%;
    position: absolute;
    .graph {
      height: 100vh;
      max-height: 100%;
      width: 100vw;
      // cursor: none;
      canvas {
        max-width: 100%;
      }
      #tree {
        width: 52%;
        height: 100%;
        animation: scaleAnim 0.5s ease-out;
        .pathology-link {
          stroke-dasharray: 4;
          stroke-dashoffset: 0;
          animation: dash 5s linear 0s infinite;
        }
        .link {
          opacity: 0.3;
          stroke-dasharray: 4;
          stroke-dashoffset: 0;
          // animation: dash 30s linear 0s infinite;
        }
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 500;
  }
}

@keyframes scaleAnim {
  from {

    transform: translate(-100vw, 0px );
    opacity: 0;
  }
  to {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}