@import '../../../styles/variables.scss';

.card {
  @include transition;
  background-color: $white;
  box-shadow: $card-shadow;
  min-height: 180px;
  border-radius: $radius;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
  &:hover {
    box-shadow: $card-shadow-hover;
  }
  &.isActive {
    .category {
      color: rgba($white , 0.5);
    }
    background-color: $dark;
    color: $white;
    h3 {
      color: $white;
    }
    .info {
      .label {
        color: rgba($white , 0.5);
      }
    }
  }
  &.pathology {
    .category {
      margin: 0px;
      font-size: 14px;
    }
    h3 {
      font-family: "AnyBody-SemiBold";
      font-size: 24px;
      margin: 0px;
    }
  }
  &.patient {
    .code-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
      width: 100%;
      h3 {
        width: 100%;
        font-family: "AnyBody-SemiBold";
        max-height: 30px;
        word-break: break-all;
        text-overflow: ellipsis;
        font-size: 24px;
        margin: 0px;
      }
    }
  }
  p {
    margin: 0px;
  }
  .info {
    margin-top: 10px ;
    display: flex;
    width: 100%;
    .label {
      width: 45%;
      color: rgba($dark , 0.5);
    }
    .value {
      max-width: 55%;
      word-break: break-all;
    }
  }
}

.color-label {
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: -1;
  transform: translate(-13px , -13px);
  border-radius: 30px;
}