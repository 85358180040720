@import '../../../../styles/variables.scss';

.timeline {
  display: flex;
  justify-content: flex-start;
  .timeline-container {
    @include transition;
    width: 100%;
    height: 100%;
    &.panelIsActive {
      width: 40%;
    }
    .timeline-graph {
      width: 100%;
      height: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        @include transition;
        font-size: 250px;
        text-align: center;
        width: 50%;
        letter-spacing: 40px;
        opacity: 0.06;
        &.panelIsOpen {
          font-size: 150px;
          letter-spacing: 20px;
        }
      }
      canvas {
        position: absolute;
        max-width: 100%;
      }
    }
  }
}