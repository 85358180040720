@import '../../../../styles/variables.scss';

.geography {
  display: flex;
  justify-content: flex-end;
  .maps-container {
    width: calc(100%);
    height: 100%;
    display: flex;
    justify-content: center;
    .map {
      transform: scale(1);
      @include animation-scale(slideInScale, 0, 1);
      animation: slideInScale 0.2s linear ;
      animation-fill-mode: forwards;
      canvas {
        max-width: 100%;
      }
    }
  }
  .nav-map {
    width: 8%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    button {
      @include transition;
      font-family: "Ubuntu-Regular";
      font-size: 14px;
      opacity: 0.3;
      img {
        width: 60px;
      }
      p {
        margin-top: 5px;
      }
      &:hover {
        opacity: .8;
      }
      &.isActive {
        opacity: 1;
      }
    }
  }
}