$bg-color: #FAFBFB;
$white: #FFFFFF;
$dark: #192243;
$dark-lighter: #5F657C;
$primary: #4744BA;
$primary-dark: rgb(45, 42, 145);
$primary-extra-light: rgb(231, 235, 247);

$success: #98CA95;
$error : red;

$shadow: 0px 0px 13px -2px rgba(156,167,177,0.30);

$card-shadow: 0px 10px 20px -4px rgba(136,136,136,0.25);
$card-shadow-hover: 0px 10px 15px 8px rgba(136,136,136,0.35);

$panel-shadow: 0px 5px 8px 10px rgba(136,136,136,0.35);

$shadow-hover: 0px 0px 10px -2px rgba(80, 80, 80, 0.603);

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 576px;

$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$small : 13px;
$regular : 16px;
$h2 : 18px;
$h1 : 25px;

$radius: 3px;

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-Bold";
	src: url("../assets/fonts/Anybody-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-SemiBold";
	src: url("../assets/fonts/Anybody-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Anybody-Regular";
	src: url("../assets/fonts/Anybody-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Ubuntu-Regular";
	src: url("../assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin slideInLogin {
	@include animation-mixin-y(slideIn, 30px, 0);
	animation-name: slideIn;
  animation-duration: 0.2s;
}

@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin btn {
  text-align: center;
	cursor: pointer;
	font-family: "Montserrat-Regular";
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $primary-extra-light inset !important;
}