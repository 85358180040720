@import '../../../styles/variables.scss';

.years-picker {
  position: absolute;
  left: 0px;
  width: 20%;
  height: calc(100% - 40px);
  margin: 20px;
  padding-bottom: 20px;
  z-index: 3000;
  button {
    text-align: left;
    margin-top: 10px;
    font-size: 12px;
    padding: 0px;
    transform: translateX(20px);
  }
  .not-active {
    opacity: 0.5;
  }
  .type-toggle {
    .type {
      font-size: 16px;
    }
  }
  .years {
    margin-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    .year {
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include transition;
      .year-bar {
        @include transition;
        .year-label {
          position: absolute;
          font-size: 24px;
          @include transition;
        }
      }
    }
  }
}