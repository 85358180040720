@import '../../../styles/index.scss';

.account-page {
  .row {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      justify-content: center;
      flex-direction: row;
    }
    .col-2 {
      width: 100%;
      .form-section {
        min-height: 183px;
      }
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
      }
    }
    .col-1 {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .form-section {
      @include transition;
      min-height: 64px;
      margin-top: $margin-md;
      padding: $margin-md;
      .title {
        display: flex;
        justify-content: center;
        margin-bottom: $margin-md;
        color: $dark;
        h3 {
          margin-left: 10px;
          text-transform: uppercase;
        }
      }
      .btn-container {
        display: flex;
        justify-content: center;
        &.password {
          margin: 20px;
        }
      }
      input {
        width: 100%;
      }
    }
  }
  a {
    position: absolute;
    bottom: 10px;
    margin:$margin-md;
    color: rgba($dark , 0.5);
    text-decoration: underline;
  }
}