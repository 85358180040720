@import '../../../styles/variables.scss';

.login {
  overflow: auto;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-family: "Ubuntu-Regular";
  label {
    font-family: "Ubuntu-Regular";
  }
  h1 {
    text-transform: uppercase;
    font-size: 21px;
    font-family: "Anybody-Bold";
  }
  .col-form {
    width: 100%;
    min-height: 100%;
    padding: $margin-md;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      width: 45%;
    }
    a {
      position: absolute;
      bottom: 10px;
      margin:$margin-md;
      color: rgba($dark , 0.5);
      text-decoration: underline;
    }
    .title {
      color: $dark;
      text-transform: uppercase;
      text-align: center;
      font-family: "Anybody-Bold";
      line-height: 90%;
      .name {
        font-size: 31px;
        letter-spacing: 4px;
        font-family: "Anybody-Bold";
      }
      span {
        font-size: 12px;
        font-family: "Ubuntu-Regular";
      } 
    }
    .form-reset {
      &.message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin: $margin-md
        }
      }
    }
    form {
      &.form-reset {
        @include animation-mixin-y(slideInLogin, 50px, 0);
        animation-name: slideInLogin;
        animation-duration: 0.2s;
      }
      &.form-login {
        @include animation-mixin-y(slideIn, 50px, 0);
        animation-name: slideIn;
        animation-duration: 0.2s;
      }
      width: 90%;
      max-width: 400px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .eye {
        svg {
          float: right;
          transform: translate(-20px , -62px)
        }
        &-reset {
          svg {
            float: right;
            transform: translate(-20px , -38px)
          }
        }
      }
      .btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-reset {
          margin:$margin-md;
          color:$dark;
          text-decoration: underline;
        }
      }
    }
  }
  .col-img {
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      width: 55%;
      background-image: url('../../../assets/images/login.jpg');
      background-size: cover;
    }
  }
}