@import '../../../styles/variables.scss';

.nav-viz {
  @include transition;
  position: fixed;
  bottom: 0px;
  left: calc(50% - 200px);
  width: 400px;
  height: 50px;
  background-color: $dark;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50px);
  &.isActive {
    transform: translateY(0px);
  }
  a {
    text-align: center;
    text-decoration: none;
    padding: 10px 15px;
    color: rgba($white , 0.3);
    &:hover {
      color: rgba($white , 0.6);
    }
    &.isActive {
      color: $white;
    }
  }
}