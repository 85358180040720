@import '../../../../../styles/variables.scss';

.genealogy {
  width: 100%;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .circle {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    transform: translate(-20px , -10px);
  }
  .gender {
    opacity: 0.5;
  }
  .parents {
    display: flex;
    .parent {
      margin: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 160px;
      max-width: 160px;
      .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 29px;
        height: 29px;
        padding: 0px;
        margin: 0px;
        &:hover {
          transform: scale(1);
        }
      }
      .code {
        padding: 5px 13px;
        font-family: "AnyBody-SemiBold";
        border-radius: $radius;
        font-size: 16px;
        background-color: $white;
        min-width: 130px;
        border: 2px solid #8C90A1;
        &.unknown {
          color: #8C90A1;
        }
        p {
          margin: 0px;
          text-align: center;
          word-break: break-all;
        }
      }
      .btn-container {
        position: absolute;
        right: -25px;
        .btn-primary {
          margin: 5px;
        }
      }
    }
  }
  .child {
    padding: 5px 13px;
    font-family: "AnyBody-SemiBold";
    border-radius: $radius;
    background-color: $white;
    border: 2px solid #8C90A1
  }
}
