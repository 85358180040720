@import '../../../styles/index.scss';

.toaster {
  @include transition;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(50% - 10px);
  padding: 5px;
  z-index: 3000;
  transform: translateY(-100px);
  box-shadow: $shadow;
  background-color: $success;
  color: $white;

  p {
    margin: 0px;
    text-align: center;
  }
  &.success {
    background-color: $success;
    color: $white;
  }
  &.error {
    background-color: $error;
    color: $white;
  }
  &.isActive {
    transform: translateX(0px);
  }
}